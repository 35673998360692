import styles from "./Tablet.module.css";
import React, { useRef, useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Tablet = () => {
  const myRef = useRef(null);
  function scrollToDiv() {
    myRef.current.scrollIntoView({ behavior: "smooth" });
  }

  const [currentSlide, setCurrentSlide] = useState(3);
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    beforeChange: (current, next) => setCurrentSlide(next),
    ref: sliderRef,
  };

  const goToNextSlide = () => {
    sliderRef.current.slickNext();
  };

  const goToPrevSlide = () => {
    sliderRef.current.slickPrev();
  };

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    sendMail();
    console.log({ name, email, message });
    setName('');
    setEmail('');
    setMessage('');
  };


  const sendMail = () => {
    window.Email.send({
      SecureToken: "c54ef3af-12a2-45cc-ad79-f9a743cbcc79",
      To: 'office@linkplus-it.com',
      From: 'noreply@linkplus-it.com',
      Subject: `This email is from ${name} ${email}, from AI-Website`,
      Body: `${message}`
    }).then(() => {
      alert('Email Sent')
    }).catch((err) => {
      console.log('error', err);
    });
  }


  return (
    <div className={styles.tablet}>
      <div className={styles.tabletResponsive}>
        <div className={styles.tabletChild} />
        <div className={styles.meee23UiuxDesignForAProdu} />
        <div className={styles.groupParent}>
          <div className={styles.loggo1Parent}>
            <img className={styles.loggo1Icon} alt="" src="/logo_new.png" />
            <div className={styles.linkplusItParent}>
              <div className={styles.linkplusIt}>LinkPlus IT</div>
              <div className={styles.beyondCoding}>BEYOND CODING</div>
            </div>
          </div>
          <b className={styles.bridgingTheGapContainer}>
            <p
              className={styles.bridgingTheGap}
            >{`Bridging the gap between `}</p>
            <p className={styles.technologyAndPeople}>technology and PEOPLE</p>
          </b>
          <div className={styles.withOurInnovativeContainer}>
            <span>{`with `}</span>
            <span className={styles.ourInnovative}>
              our innovative and excellent solutions
            </span>
          </div>
          <img
            className={styles.c662785d865245e784dcAdc885Icon}
            alt=""
            src="/c662785d865245e784dcadc885ebe798-22@2x.png"
          />
        </div>
        <div className={styles.groupContainer}>
          <div className={styles.aboutUsParent}>
            <div className={styles.linkplusIt}>ABOUT US</div>
            <div className={styles.unlockingThePowerContainer}>
              <p className={styles.bridgingTheGap}>{`Unlocking the power `}</p>
              <p className={styles.bridgingTheGap}>of technology</p>
              <p className={styles.technologyAndPeople}>
                <span>with</span>
                <span className={styles.softwareDevelopment}> LinkPlus IT</span>
              </p>
            </div>
          </div>
          <div className={styles.establishedInNovember}>
            Established in November 2013 with a mission to deliver the most
            straightforward solutions for even the most intricate problems.
          </div>
          <div className={styles.roadmapWrapper}>
            <div className={styles.roadmap}>
              <button onClick={scrollToDiv}>ROADMAP</button>
            </div>
          </div>
          <div className={styles.groupWrapper}>
            <div className={styles.asds1Parent}>
              <img className={styles.asds1Icon} alt="" src="/asds-11@2x.png" />
              <img
                className={styles.meee23UiuxDesignForAProduIcon}
                alt=""
                src="/meee23-uiux-design-for-a-product-website-artificial-intelligenc-79e943fab8c2490b8aaab5f4b7285edc-21@2x.png"
              />
            </div>
          </div>
          <div className={styles.in2021LinkplusItContinuedParent}>
            <div className={styles.in2021LinkplusContainer}>
              <p className={styles.p}>
                <b>2021</b>
              </p>
              <p className={styles.in2021Linkplus}>
                In 2021, LinkPlus IT continued to diversify its portfolio of
                services and developed applications for various industries such
                as Airline, Marketing, Travel, Medical Printing and Gaming.
              </p>
            </div>
            <div ref={myRef} className={styles.linkplusLinkplusItContainer}>
              <p className={styles.p}>
                <b>2022</b>
              </p>
              <p className={styles.in2021Linkplus}>
                LinkPlus LinkPlus IT was awarded by Deloitte’s Technology CE
                Fast 50 as Laureate of the impact stars category for its digital
                impact in society, business and environment.
              </p>
            </div>
          </div>
          <div className={styles.weWereAbleToAttractMoreCParent}>
            <div className={styles.weWereAbleContainer}>
              <p className={styles.p}>
                <b>2019</b>
              </p>
              <p className={styles.in2021Linkplus}>
                We were able to attract more clients and take on more
                challenging projects in our field. This allowed us to further
                develop our expertise and deliver even more advanced solutions
                to our customers.
              </p>
            </div>
            <div className={styles.in2020LinkplusContainer}>
              <p className={styles.p}>
                <b>2020</b>
              </p>
              <p className={styles.in2021Linkplus}>
                In 2020, LinkPlus IT had grown to over 40 employees. Despite the
                challenges posed by the ongoing pandemic, the company was able
                to make a positive impact by providing digital learning
                solutions.
              </p>
            </div>
          </div>
          <div className={styles.linkplusItNotOnlyContinuedParent}>
            <div className={styles.weWereAbleContainer}>
              <p className={styles.p}>
                <b>2017</b>
              </p>
              <p className={styles.in2021Linkplus}>
                LinkPlus IT not only continued to use the latest software
                technologies, but also expanded its team and participated in
                various conferences across the globe such as the United States
                and lote more.
              </p>
            </div>
            <div className={styles.asOurServicesContainer}>
              <p className={styles.p}>
                <b>2018</b>
              </p>
              <p className={styles.asOurServices}>
                As our services and client base continued to grow, it became
                clear that we needed a more suitable space to accommodate our
                expanding team and operations, so we moved to new offices in the
                heart of Prishtina.
              </p>
            </div>
          </div>
          <div className={styles.thisYearMarkedATurningPoiParent}>
            <div className={styles.weWereAbleContainer}>
              <p className={styles.p}>
                <b>2015</b>
              </p>
              <p className={styles.in2021Linkplus}>
                This year marked a turning point for our company. We opened a
                new branch in Skopje, adding a team of dedicated employees
                (Macedonian and Albanian) to support our continued growth.
              </p>
            </div>
            <div className={styles.asOurServicesContainer}>
              <p className={styles.p}>
                <b>2016</b>
              </p>
              <p className={styles.asOurServices}>
                LinkPlus IT continued to leverage cutting-edge software
                technologies and became a key player in major European projects.
              </p>
            </div>
          </div>
        </div>
        <div className={styles.linkplusItGroup}>
          <div className={styles.linkplusIt2}>{`LinkPlus IT `}</div>
          <div className={styles.weUnderstandThatContainer}>
            <p className={styles.weUnderstandThat}>
              We understand that simply writing code is not enough to deliver
            </p>
            <p className={styles.technologyAndPeople}>
              effective solutions to our clients.
            </p>
          </div>
          <b className={styles.employsAHolisticContainer}>
            <p className={styles.bridgingTheGap}>Employs a holistic approach</p>
            <p className={styles.technologyAndPeople}>
              <span>{`to `}</span>
              <span className={styles.softwareDevelopment}>
                software development.
              </span>
            </p>
          </b>
          <div className={styles.methodology}>Methodology</div>
          <img
            className={styles.meee23UiuxDesignForAProduIcon1}
            alt=""
            src="/meee23-uiux-design-for-a-product-website-artificial-intelligenc-05df0bac8d134d95bcbb419d28172cd4-1@2x.png"
          />
        </div>
        <div className={styles.groupDiv}>
          <div className={styles.endToEndSolutionsParent}>
            <div className={styles.endToEndSolutions}>End-to-End Solutions</div>
            <div className={styles.weHandleEveryContainer}>
              <p className={styles.weHandleEvery}>
                We handle every aspect of the process, from initial consultation
                to final deployment, ensuring
              </p>
              <p className={styles.technologyAndPeople}>
                that you have a seamless experience and receive a fully
                functional, high-quality solution that meets your specific
                requirements
              </p>
            </div>
          </div>
          <div className={styles.staffAugmentationParent}>
            <div className={styles.staffAugmentation}>Staff Augmentation</div>
            <div className={styles.accessToA}>
              Access to a hand-picked team of skilled professionals, ready to
              work closely with you to bring your vision to life
            </div>
          </div>
          <img className={styles.sadsad3Icon} alt="" src="/sadsad-31@2x.png" />
          <b className={styles.poweringYourSuccessContainer}>
            <p className={styles.bridgingTheGap}>Powering your success</p>
            <p className={styles.bridgingTheGap}>with thE lates technologies</p>
            <p className={styles.technologyAndPeople}>
              <span>{`at `}</span>
              <span className={styles.softwareDevelopment}>LinkPlus IT .</span>
            </p>
          </b>
        </div>
        <div className={styles.linkplusItContainer}>
          <div className={styles.linkplusIt4}>{`LinkPlus IT `}</div>
          <div className={styles.weHaveUsedContainer}>
            <p className={styles.technologyAndPeopleT}>
              Content and some Code generated with ChatGPT,
            </p>
            <p className={styles.technologyAndPeopleT}>
              Images and UI /UX generated with Midjourney,
            </p>
            <p className={styles.technologyAndPeopleT}>
              Website generated with Locofy .
            </p>
          </div>
          <b className={styles.thisWebsiteWasContainer}>
            <p className={styles.bridgingTheGap}>
              THIS WEBSITE WAS BUILT USING
            </p>
            <p className={styles.aiTools}>AI TOOLS .</p>
          </b>
          <div className={styles.caretLeftParent}>
            <img className={styles.caretLeftIcon} alt="" />
            <img
              className={styles.meee23UiuxDesignForAProduIcon2}
              alt=""
              src="/meee23-uiux-design-for-a-product-artificial-intelligence-3-961dca1ab343463a845f41350be37c63-12@2x.png"
            />
            <img
              className={styles.meee23UiuxDesignForAProduIcon3}
              alt=""
              src="/meee23-uiux-design-for-a-product-website-artificial-intelligenc-ecdb96e740cb45369cd09fdd9c2d1801-12@2x.png"
            />
            <img
              className={styles.meee23UiuxDesignForAProduIcon4}
              alt=""
              src="/meee23-uiux-design-for-a-product-website-artificial-intelligenc-064af37bda464cca9fb29bb82cfbc2d3-1-12@2x.png"
            />
            <img
              className={styles.meee23UiuxDesignForAProduIcon5}
              alt=""
              src="/meee23-uiux-design-for-a-product-website-artificial-intelligenc-05df0bac8d134d95bcbb419d28172cd4-1-12@2x.png"
            />
            <img
              className={styles.meee23UiuxDesignForAProduIcon6}
              alt=""
              src="/meee23-uiux-design-for-a-product-artificial-intelligence-2-961dca1ab343463a845f41350be37c63-12@2x.png"
            />
            <img
              className={styles.meee23UiuxDesignForAProduIcon7}
              alt=""
              src="/meee23-uiux-design-for-a-product-website-artificial-intelligenc-8be481a178f14923a0372bc4d82aca9c-2-12@2x.png"
            />
            <img
              className={styles.viberImage2023031415484Icon}
              alt=""
              src="/viber-image-20230314-154848595-12@2x.png"
            />
            <img
              className={styles.capture61Icon}
              alt=""
              src="/capture6-12@2x.png"
            />
            <img
              className={styles.mmm23LaptopPhonePixarStyleIcon}
              alt=""
              src="/mmm23-laptop-phone-pixar-style-shallow-depth-of-field-cinematic-ec7932c8e9734758a6ffa74b9e082a78-12@2x.png"
            />
            <img
              className={styles.meee23UiuxDesignForAProduIcon8}
              alt=""
              src="/meee23-uiux-design-for-a-product-logo-artificial-intelligence-b-656ef8657d304d019b904501aeb98400-12@2x.png"
            />
            <img
              className={styles.meeeeYoungHappyManProgrammIcon}
              alt=""
              src="/meeee-young-happy-man-programming-in-laptop-shallow-depth-of-fi-9a02e4e4d66847cc9185fec2aa72da55-12@2x.png"
            />
            <img
              className={styles.createMeALandingpage1}
              alt=""
              src="/create-me--a-landingpage-12@2x.png"
            />
          </div>
        </div>
        <div className={styles.groupParent1}>
          <div className={styles.groupFrame}>
            <div className={styles.groupWrapper1}>
              <div className={styles.groupWrapper1}>
                <div className={styles.pristinaKosovoParent}>
                  <div className={styles.pristinaKosovo}>
                    <p className={styles.p}>Pristina, Kosovo</p>
                  </div>
                  <div className={styles.strTiranaIconContainer}>
                    <p className={styles.p}>
                      Str. Tirana, Icon Tower - 12th Floor
                    </p>
                    <p className={styles.technologyAndPeople}>
                      No.46, Prishtine, 10000, Kosovo
                    </p>
                  </div>
                </div>
                <div className={styles.groupParent3}>
                  <div className={styles.skopjeNorthMacedoniaParent}>
                    <div className={styles.skopjeNorthMacedoniaContainer}>
                      <p className={styles.p}>SKOPJE, NORTH MACEDONIA</p>
                    </div>
                    <div className={styles.borisTrajkovski1Container}>
                      <p className={styles.p}>Boris Trajkovski 1 2/75</p>
                      <p className={styles.technologyAndPeople}>
                        Skopje 1000, North Macedonia
                      </p>
                    </div>
                  </div>
                  <div className={styles.emailInfolinkplusItcom}>
                    Email: info@linkplus-it.com
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*<div className={styles.nameParent}>*/}
          {/*  <div className={styles.name}>Name</div>*/}
          {/*  <div className={styles.groupChild} />*/}
          {/*  <div className={styles.email}>Email</div>*/}
          {/*  <div className={styles.groupItem} />*/}
          {/*  <div className={styles.message}>Message</div>*/}
          {/*  <div className={styles.sendWrapper}>*/}
          {/*    <div className={styles.send}>SEND</div>*/}
          {/*  </div>*/}
          {/*  <div className={styles.groupInner} />*/}
          {/*</div>*/}
          <form className={styles.nameParent}>
            <div className={styles.name}>Name</div>
            <input
              className={styles.groupChild}
              type="text"
              name="name"
              value={name}
              onChange={(event) => setName(event.target.value)}
              required
            />
            <div className={styles.email}>Email</div>
            <input
              className={styles.groupItem}
              type="email"
              name="email"
              value={email}
              onChange={(event) => setEmail(event.target.value)}
              required
            />
            <div className={styles.message}>Message</div>
            <textarea
              className={styles.groupInner}
              name="message"
              rows="5"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
              required
            ></textarea>
            <div className={styles.sendWrapper} onClick={sendMail}>
              <button className={styles.send} type="submit" onClick={handleSubmit}>
                SEND
              </button>
            </div>
          </form>

          <div className={styles.groupParent4}>
            <div className={styles.linkplusItAllRightsReserveWrapper}>
              <div className={styles.copyrightdesktop}>
                @2023 LINKPLUS IT All rights Reserved
              </div>
              <div className={styles.iconSectionTablet}>
                <a
                  href="https://www.facebook.com/LinkPlusIT/"
                  className={styles.groupIcons}
                >
                  Facebook
                </a>
                <a
                  href="https://www.instagram.com/linkplus_it/?hl=en"
                  className={styles.groupIcons}
                >
                  Instagram
                </a>
                <a
                  href="https://www.linkedin.com/company/linkplus-it/mycompany/"
                  className={styles.groupIcons}
                >
                  LinkedIn
                </a>
                <a
                  href="https://www.tiktok.com/@linkplusit?lang=en"
                  className={styles.groupIcons}
                >
                  TikTok
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.dsjfkdsjf1Parent}>
          <img
            className={styles.dsjfkdsjf1Icon}
            alt=""
            src="/dsjfkdsjf-12@2x.png"
          />
          <div className={styles.experienceThePowerOfTeamCWrapper}>
            <div className={styles.experienceThePower}>
              Experience the power of team culture with LinkPlus IT's engaging
              activities
            </div>
          </div>
          <div className={styles.advanceYourCareerWithCompeWrapper}>
            <div className={styles.experienceThePower}>
              Advance your career with competitive salaries and growth
              opportunities
            </div>
          </div>
          <div className={styles.joinAFlexibleForwardThinkWrapper}>
            <div className={styles.experienceThePower}>
              Join a flexible, forward thinking team at LinkPlus IT
            </div>
          </div>
          <div className={styles.experienceTheBestOfBothWoWrapper}>
            <div className={styles.experienceThePower}>
              Experience the best of both worlds withremote work opportunities
            </div>
          </div>
          <div className={styles.joinTheTeamRevolutionizingParent}>
            <b className={styles.joinTheTeamContainer}>
              <p className={styles.technologyAndPeople}>Join the team</p>
              <p className={styles.technologyAndPeople}>&nbsp;</p>
              <p className={styles.technologyAndPeople}>revolutionizing</p>
              <p className={styles.technologyAndPeople}>&nbsp;</p>
              <p className={styles.aiTools}>industries</p>
            </b>
            {/*<div className={styles.joinUsNowWrapper}>*/}
            {/*  <div className={styles.experienceThePower}>JOIN US NOW</div>*/}
            {/*</div>*/}
            <div className={styles.joinUsNowWrapper}>
              <a
                href="https://linkplus-it.com/jobs/"
                className={styles.experienceThePower}
              >
                JOIN US NOW
              </a>
            </div>
          </div>
        </div>
        <div className={styles.groupParent5}>
          <div className={styles.groupWrapper2}>
            <div className={styles.sds1Parent}>
              <img className={styles.sds1Icon} alt="" src="/sds-11@2x.png" />
              <img
                className={styles.majkmSimplySectionWebsiteSIcon}
                alt=""
                src="/majkm-simply-section-website-same-as-in-image-black-background--c864137c5e394f2baa6e00b344fcf796-2-11@2x.png"
              />
            </div>
          </div>
          <div className={styles.groupWrapper3}>
            <div className={styles.technologiesParent}>
              <div className={styles.technologies}>TECHNOLOGIES</div>
              <div className={styles.javaEeSpring}>
                Java EE, Spring, Hibernate,.NET Stack, Javascript, PHP, Angular,
                React.JS
              </div>
              <div className={styles.rectangleDiv} />
            </div>
          </div>
          <div className={styles.groupWrapper4}>
            <div className={styles.businessIntelligenceParent}>
              <div className={styles.businessIntelligence}>
                BUSINESS INTELLIGENCE
              </div>
              <div className={styles.dataAnalysisreportBuilding}>
                Data Analysis,Report building
              </div>
              <div className={styles.groupChild1} />
            </div>
          </div>
          <div className={styles.groupWrapper5}>
            <div className={styles.databasesParent}>
              <div className={styles.databasesParent}>
                <div className={styles.databases}>DATABASES</div>
                <div className={styles.mysqlMongodbPostgresql}>
                  MYSQL, MongoDB, PostgreSQL
                </div>
                <div className={styles.groupChild2} />
              </div>
            </div>
          </div>
          <div className={styles.groupParent6}>
            <div className={styles.groupWrapper7}>
              <div className={styles.mobileParent}>
                <div className={styles.mobile}>MOBILE</div>
                <div className={styles.reactNativeKotlin}>
                  React Native, Kotlin, Swift, Android
                </div>
              </div>
            </div>
            <div className={styles.groupChild3} />
          </div>
          <div className={styles.groupParent7}>
            <div className={styles.groupParent8}>
              <div className={styles.groupWrapper8}>
                <div className={styles.qualityAssuranceParent}>
                  <div className={styles.qualityAssurance}>
                    QUALITY ASSURANCE
                  </div>
                  <div className={styles.seleniumGherkinManual}>
                    Selenium, Gherkin, Manual Testing, Cypress
                  </div>
                </div>
              </div>
              <div className={styles.groupChild4} />
            </div>
            <div className={styles.groupParent9}>
              <div className={styles.groupWrapper9}>
                <div className={styles.projectManagementParent}>
                  <div className={styles.projectManagement}>
                    Project management
                  </div>
                  <div
                    className={styles.agileScrum}
                  >{`Agile & Scrum Methodologies`}</div>
                </div>
              </div>
              <div className={styles.groupChild5} />
              <div className={styles.groupWrapper9}>
                <div className={styles.projectManagementParent}>
                  <div className={styles.projectManagement}>
                    Project management
                  </div>
                  <div
                    className={styles.agileScrum}
                  >{`Agile & Scrum Methodologies`}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.groupWrapper11}>
            <div className={styles.technologyParent}>
              <div className={styles.technology}>Technology</div>
              <b className={styles.whatWeUse}>WHAT WE USE ?</b>
              <div className={styles.ourExpertiseLies}>
                Our expertise lies in harnessing the power of cutting-edge
                innovations to elevate your digital experience to new heights.
              </div>
            </div>
          </div>
          <div className={styles.technologyGroup}>
            <div className={styles.technology1}>TECHNOLOGY</div>
            <div className={styles.stack}>STACK</div>
            <div className={styles.weUse}>WE USE</div>
          </div>
        </div>
        <div className={styles.highlightsOfOurProjectsParent}>
          <b className={styles.highlightsOfOurContainer}>
            <p className={styles.weHandleEvery}>
              <span className={styles.highlights}>Highlights</span>
              <span> of</span>
            </p>
            <p className={styles.weHandleEvery}>&nbsp;</p>
            <p className={styles.technologyAndPeople}>our projects</p>
          </b>
          <img
            onClick={goToPrevSlide}
            className={styles.groupChild6}
            alt="prev"
            src="/caretleft.svg"
          />
          <img
            onClick={goToNextSlide}
            className={styles.groupChild20}
            alt="next"
            src="/caretright.svg"
          />
          {/*<img className={styles.groupChild6} alt="" src="/group-1641.svg" />*/}
          <div className={styles.rectangleParent}>
            <div className={styles.groupChild7} />
            <div className={styles.groupChild8} />
          </div>
          <div className={styles.groupWrapper12}>
            <div className={styles.groupParent10}>
              {/*<div className={styles.rectangleGroup}>*/}
              {/*  <div className={styles.groupChild9} />*/}
              {/*  <div*/}
              {/*    className={styles.taxiLimousine}*/}
              {/*  >{`Taxi & Limousine Service App`}</div>*/}
              {/*</div>*/}
              {/*<div className={styles.rectangleContainer}>*/}
              {/*  <div className={styles.groupChild9} />*/}
              {/*  <div*/}
              {/*    className={styles.taxiLimousine}*/}
              {/*  >{`Taxi & Limousine Service App`}</div>*/}
              {/*</div>*/}
              {/*<div className={styles.rectangleParent1}>*/}
              {/*  <div className={styles.groupChild9} />*/}
              {/*  <div className={styles.helpDeskApp}>Help Desk App</div>*/}
              {/*</div>*/}
              <Slider {...settings}>
                <div>
                  <div className={styles.sliderDivTablet}>
                    <h2>Real Estate/Property Web & Apps</h2>
                    <p>
                      Platform is trading place where the user can sell the
                      items, rent them or buy different things. There are some
                      categories as: real estates, vehicles, motorcycles, used
                      items and jobs.
                    </p>
                  </div>
                </div>

                <div>
                  <div className={styles.sliderDivTablet}>
                    <h2>Help Desk App</h2>
                    <p>
                      The purpose of the Help desk app is to provide another
                      channel of communication between the field agents and the
                      back office. The agents will be able to retrieve the
                      tickets, view the details and perform limited amount of
                      actions. The Help desk app is developed by using the Clean
                      Swift architecture. The application it has log in,
                      dashboard, all tickets, Log out, user profile, search eld,
                      comments, client prole, devices.
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles.sliderDivTablet}>
                    <h2>Taxi & Limousine Service App</h2>
                    <p>
                      Flotilla Taxi & Limousinen offers you a wide range of
                      services related to business and private passenger
                      transport.With the new Flotilla app, trips can be reserved
                      with one click and pay conveniently via the app. A list of
                      the booked trips and individual invoicing are part of the
                      functions. The app is the perfect solution for business
                      people and private individuals who simply want to bill
                      their trips.
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles.sliderDivTablet}>
                    <h2>Location Tracking/Geofencing App</h2>
                    <p>
                      The application relies heavily on the device location
                      services and geofencing. We improved the application by
                      optimizing the performance and the device battery life.
                      Added option for the user to change preferences and do a
                      preview of the recent activity. To full the client’s needs
                      for better user related data we added Integration with
                      Intercom and Firebase integration. Some of the
                      technologies used here are: Django, Android/Kotlin,
                      iOS/Swift (Mobile).
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles.sliderDivTablet}>
                    <h2>Camunda Platform</h2>
                    <p>
                      Integration and implementation of Camunda which is an
                      open-source workflow and decision automation platform.
                      Camunda BPM helps with creating workflow and decision
                      models, operating deployed models in production, and
                      allowing users to execute workflow tasks assigned to them.
                      We have implemented 7 processes which have created
                      scalability of business processes in Lufthansa Industry
                      Solutions.
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles.sliderDivTablet}>
                    <h2>E-commerce Platform</h2>
                    <p>
                      Development and implementation of models of e-commerce in
                      the website. The modules implemented are: Model for travel
                      insurance, model for Kasko (mini kasko), Model for
                      apartments insurance (Desktop).
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles.sliderDivTablet}>
                    <h2>Access Platform App</h2>
                    <p>
                      A solution for Access Control will be implemented to allow
                      users to scan their cards and gain access to open doors in
                      the objects where access controllers are installed.The
                      software has various reports and charts regarding users,
                      the time when the user has opened the door, time when the
                      user leaves, calculated hours in the objects.
                    </p>
                  </div>
                </div>
                <div>
                  <div className={styles.sliderDivTablet}>
                    <h2>Social Platform App</h2>
                    <p>
                      The mission of Dua is to connect over 12 million Albanians
                      around the globe. Be it Albanians from Kosovo, Albania,
                      Macedonia, Montenegro, Turkey, Switzerland, the UK, and
                      beyond, dua.com aim to empower relationships and
                      meaningful connections with peers. Search for partner
                      anyplace, anywhere, anytime, and meet them in person.
                      (Mobile).
                    </p>
                  </div>
                </div>
              </Slider>
            </div>
          </div>
          <div className={styles.groupWrapper13}>
            <div className={styles.meee23UiuxDesignForAProduParent}>
              <img
                className={styles.meee23UiuxDesignForAProduIcon9}
                alt=""
                src="/meee23-uiux-design-for-a-product-website-artificial-intelligenc-dcc45a1397d2460590170628ec3e69dd-12@2x.png"
              />
              <div className={styles.groupWrapper14}>
                <div className={styles.mee123ACloseShotOfAYoungWrapper}>
                  <img
                    className={styles.mee123ACloseShotOfAYoungIcon}
                    alt=""
                    src="/mee123-a-close-shot-of-a-young-happy-human-working-in-his-codin-850da9bb022e4b088215fcd99fc07a3a-22@2x.png"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tablet;
