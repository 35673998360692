import React, { useState, useEffect } from 'react';

import Mobile from "./pages/Mobile";
import Tablet from "./pages/Tablet";
import Desktop from "./pages/Desktop";


function App() {
  const [screenSize, setScreenSize] = useState(getScreenSize());

  useEffect(() => {

    const handleResize = () => {
      setScreenSize(getScreenSize());
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function getScreenSize() {
    if (window.matchMedia('(max-width: 768px)').matches) {
      return 'mobile';
    } else if (window.matchMedia('(min-width: 769px) and (max-width: 1240px)').matches) {
      return 'tablet';
    } else if (window.matchMedia('(min-width: 1241px)').matches){
      return 'desktop';
    }
  }

  if (screenSize === 'mobile') {
    return <Mobile />;
  } else if (screenSize === 'tablet') {
    return <Tablet />;
  } else if(screenSize === 'desktop') {
    return <Desktop />;
  }
}

export default App;